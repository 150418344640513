import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { deleteHeader, deleteQuestion, getQuestion } from '../redux/actions/questionAction';
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineDeleteForever } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";
import authReducer from '../redux/reducers/authReducer';
import { BASE_URL } from '../utils/config';

const Questions = () => {

  const { subject, year } = useParams();

  const { auth, exam } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [questions, setQuestions] = useState([])

  useEffect(() => {
    if (auth) {
      const data = { subject, year };
      dispatch(getQuestion({ data, auth }))
    }
  }, [auth]);


  useEffect(() => {
    setQuestions(exam.questions)
  }, [exam.questions]);

  function renderHeader(item) {
    return (
      <div>
        <div className="flex items-center justify-between gap-2">

          <h2 className="text-xl">{item.question_id}.</h2>

          <div
            onClick={() => {
              if (
                window.confirm(
                  `Are you sure you want to delete header?`
                )
              ) {
                const id = item.id2
                dispatch(deleteHeader(id, auth));
              }
            }}
          >
            <MdOutlineDeleteForever style={{ fontSize: "25px", color: "red" }} />
          </div>
        </div>

        {item.header1 && <>
          <div className="mt-1 text-xl text-gray-800 font-bold">{item.header1}</div>
        </>}

        {item.header2 && <>
          <div className="mt-2 text-lg text-gray-800 font-body">{item.header2}</div>
        </>}

        {item.paragraph && <>
          <div className="mt-3 text-base text-gray-800 font-body">{item.paragraph}</div>
        </>}
      </div>
    )
  }

  return (
    <div className="flex m-3 flex-wrap justify-center gap-5 items-center">
      {questions?.map((item) => (
        <div key={item.title} className="bg-white shadow-lg w-full flex-col   dark:text-gray-200 dark:bg-secondary-dark-bg  p-4 rounded-2xl ">


          {item.id == -1 && renderHeader(item)}

          {item.id != -1 && <>

            <div className="flex items-center justify-between gap-2">

              <h2 className="text-xl">{item.question_id}.</h2>
              {/* </>} */}
              <div className="flex items-center justify-center gap-2">
                <Link to={`/EditQuestion/${item.id}`}>
                  <CiEdit style={{ fontSize: "20px", color: "green", marginRight: 20 }} />
                </Link>

                <div
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you want to delete question ${item.question_id}?`
                      )
                    ) {
                      const id = item.id
                      dispatch(deleteQuestion(id, auth));
                    }
                  }}
                >
                  <MdOutlineDeleteForever style={{ fontSize: "25px", color: "red" }} />
                </div>
              </div>
            </div>

            {item.question_pic && <div className='flex justify-center'>
              <img
                className="mb-4 rounded-lg w-auto max-w-60 h-auto sm:mb-0 xl:mb-4 2xl:mb-0"
                src={`${BASE_URL}${item.question_pic}`}
                alt="Selected Image"
              />
            </div>}
            {item.question && <>
              <div className="mt-1 text-xl text-gray-800 font-bold">{item.question}</div>
            </>}

            <ul className='flex flex-wrap gap-5 mt-4'>
              <li
                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
                ${"option1" == item.answer ? 'bg-green-400' : null}`}
              >{item.option1}</li>
              <li
                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
            ${"option2" == item.answer ? 'bg-green-400' : null}`}
              //  className={"option2" === item.answer ? 'selected-answer' : null}
              >{item.option2}</li>
              <li
                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
            ${"option3" == item.answer ? 'bg-green-400' : null}`}
              >{item.option3}</li>
              <li
                className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
            ${"option4" == item.answer ? 'bg-green-400' : null}`}
              >{item.option4}</li>
            </ul>
            
            <div className='flex gap-5'>
            {item.grade && <div className="mt-5 text-lg text-gray-400 font-bold">Grade {item.grade}</div>}
            {item.unit && <div className="mt-5 text-lg text-gray-400 font-bold">Unit {item.unit}</div>}
            </div>

            <div className="mt-4 text-lg text-gray-400 font-bold">explanation</div>
            {item.explanation_pic && <div className='flex justify-center'>
              <img
                className="rounded-lg w-auto max-w-60 h-auto sm:mb-0 xl:mb-4 2xl:mb-0"
                src={`${BASE_URL}${item.explanation_pic}`}
                alt="Selected Image"
              />
            </div>}
            <div className=" text-gray-400 font-body">{item.explanation}</div>

          </>}
        </div>

      ))}

    </div>
  )
}

export default Questions