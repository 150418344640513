import { USER_TYPES } from '../actions/userAction'
import { EditData, DeleteData } from '../actions/globalTypes'

const initialState = {
    users: [],
    examPaidUsers: [],
    coursePaidUsers: [],
}

const usersReducer  = (state = initialState, action) => {
    switch (action.type){
        case USER_TYPES.GET_USER:
            return {
                ...state,
                
                users: action.payload,
            };
        case USER_TYPES.CREATE_USER:
            return {
                ...state,
                users: [action.payload, ...state.users]
            };
        case USER_TYPES.DELETE_USER:
            return {
                ...state,
                users: DeleteData(state.users, action.payload)
            };
        case USER_TYPES.GET_EXAMPAIDUSER:
            return {
                ...state,
                examPaidUsers: action.payload
            };
        case USER_TYPES.GET_COURSEPAIDUSER:
            return {
                ...state,
                coursePaidUsers: action.payload
            };
            
            
        default:
            return state;
        // case GLOBALTYPES.USERS:
        //     return action.payload;
        // default:
        //     return state;
    }
}


export default usersReducer 