import { QUESTION_TYPES } from '../actions/questionAction'
import { EditData, DeleteData } from '../actions/globalTypes'

const initialState = {
    years: [],
    questions: [],
    questionByid: '',
    units: []
}

const usersReducer  = (state = initialState, action) => {
    switch (action.type){
        case QUESTION_TYPES.GET_YEAR:
            return {
                ...state,
                
                years: action.payload,
            };
        case QUESTION_TYPES.GET_QUESTION:
            return {
                ...state,
                questions: action.payload
            };
        case QUESTION_TYPES.GET_QUESTIONBYID:
            return {
                ...state,
                questionByid: action.payload
            };
        case QUESTION_TYPES.GET_UNIT:
            return {
                ...state,
                units: action.payload
            };
        default:
            return state;
        // case GLOBALTYPES.USERS:
        //     return action.payload;
        // default:
        //     return state;
    }
}


export default usersReducer 