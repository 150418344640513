import { GLOBALTYPES } from './globalTypes';
import { imageUpload, deletefile } from '../../utils/imageUpload';
import {
  postDataAPI,
  getDataAPI,
  patchDataAPI,
  deleteDataAPI,
} from '../../utils/fetchData';
import { notification } from 'antd';
import { BASE_URL } from '../../utils/config';
import axios from 'axios';


export const HOME_TYPES = {
    CREATE_HOMEVIDEO: 'CREATE_HOMEVIDEO',
    GET_HOMEVIDEO: 'GET_HOMEVIDEO',
    DELETE_HOMEVIDEO: 'DELETE_HOMEVIDEO',
  };


  export const createHomeVideo = (formData, token) => async (dispatch) => {
    console.log("formData, token____________________________", formData, token )
    try {
      // dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

      // const res = await postDataAPI(
      //   'course/createHomeVideo/',
      //   formData ,
      //   token
      // );

      const url = 'course/createHomeVideo/'
      const res = await axios.post(`${BASE_URL }/api/${url}`, formData, 
        {headers: {'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token},
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total || 1; // Handle potential undefined total
          const progress = Math.round((progressEvent.loaded * 100) / total);
          // console.log("upload progress:", progress, "%"); // Log with informative message
          dispatch({ type: GLOBALTYPES.UPLOAD, payload: { uploadHome: progress } });
        },

        })

    //   dispatch({
    //     type: QUESTION_TYPES.CREATE_YEAR,
    //     payload: res.data,
    //   });

      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { success: 'Successfully created!' },
      });

      // dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });

    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

  export const  getHomeVideo = (token) => async (dispatch) => {
    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: true });
      const res = await getDataAPI(`course/getHomeVideo/`, token);
      console.log("homevideo YYYYYYYY", res.data)

      dispatch({
        type: HOME_TYPES.GET_HOMEVIDEO,
        payload: res.data,
      });

      
  
      dispatch({ type: GLOBALTYPES.ALERT, payload: false });
    } catch (err) {
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };

  
  export const deleteHomeVideo =(id, token) => async (dispatch) => {
    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

      const res = await deleteDataAPI(`course/deleteHomeVideo/${id}`, token);

      // console.log("delete123id Action", res)
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { success: "deleted successfully" },
      });

      dispatch({
        type: HOME_TYPES.DELETE_HOMEVIDEO,
        payload: {id},
      });

      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
      
    } catch (err) {
      //console.log(err)
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: err.response.data.msg },
      });
    }
  };
