export const getAuthHeader = (token) => {
  // const token = JSON.parse(localStorage.getItem('eduhub-user')).token
  // const token = JSON.parse(localStorage.getItem('access_token')).token
 // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzIxNTUwMDk1LCJpYXQiOjE3MjEzODYwNTQsImp0aSI6Ijc4M2U5NTZkZmU1NTQ5ZmNhMmQ0MzQxZGY3ZmI4OTkyIiwidXNlcl9pZCI6Mn0.r-DzNWWD-qVLm62XCRj7gu9GW8FS_5bpvXITksV19WI'
  
 
 return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getMultiPartAuthHeader = () => {
  // const token = JSON.parse(localStorage.getItem('eduhub-user')).token
  const token = JSON.parse(localStorage.getItem('access_token')).token
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  }
}

export const getS3Credintials = () => ({
  accessKeyId: '',
  secretAccessKey: ''
})
