import React, { useEffect, useState } from 'react'
import { FlexSectionHeader } from '../edu-hub/style'
import {Button, Typography, Modal, Input, List, Collapse, Col, Empty,Form, Row, Switch } from 'antd'
import {
    CaretRightOutlined,
    ExclamationCircleOutlined,
    PlusOutlined
  } from '@ant-design/icons'
// import { courses, user } from '../data/course_data'
// import { user } from '../data/course_data'
import CourseCard from '../edu-hub/components/CourseCard'
import Spinner from '../edu-hub/components/Spinner'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCourse,
  getAllCourses,
  deleteCourse,
  unEnroll
} from '../redux2/reducers/courseReducer'
import { ContactlessOutlined } from '@mui/icons-material'

const { Title, Text } = Typography

const Courses = () => {
    const dispatch = useDispatch()
    // const history = useHistory()
    const navigate = useNavigate();

    const token = useSelector((state) => state.auth.token)


    useEffect(() => {
      if(token){
        
      dispatch(getAllCourses(token))
      }
      // dispatch(getAllDeadlines())
    }, [dispatch, token])

    const [filter, setFilter] = useState('')

    const user = useSelector((state) => state.auth.user)
    const courses = useSelector((state) =>
      state.courses.data.filter((course) => course.status !== 'archived')
    )
    const loading = useSelector((state) => state.courses.loading)
   
    console.log("courses", courses)

  const publicCourses = courses?.filter((course) => {
    return course.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 && course.payment === false;
})

const paiedCourses = courses?.filter((course) => {
  return course.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 && course.payment === true;
})

 
//   const handleCourseCardClick = (courseId, userId, userEnrolled) => {
//     if (userEnrolled) history.push(`/app/course/${courseId}/modules`)
//     else 
//       confirmEnrolled(courseId, userId)
//   }
  const handleCourseCardClick = (courseId, userId, userEnrolled) => {
    
  
    if (userEnrolled) {
      navigate(`/app/course/${courseId}/modules`); // Navigate to enrolled course modules
    } else {
        navigate(`/app/course/${courseId}/modules`);
    //   if (window.confirm('Are you sure you want to enroll in this course?')) {
    //     // confirmEnrolled(courseId, userId)
    // //       .then(() => navigate(`/app/course/${courseId}/modules`)) // Navigate after successful enrollment
    // //       .catch(error => {
    // //         // Handle enrollment error gracefully
    // //         console.error('Error enrolling in course:', error);
    // //         alert('An error occurred while enrolling. Please try again.');
    // //       });
    //   }
    }
  };

  // const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  const [form, setForm] = useState({ id: '', courseName: '', description: '', payment: false, image: null });

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const formData = new FormData();
    {form.id && formData.append('id', form.id)}
    formData.append('name', form.courseName);
    formData.append('description', form.description);
    formData.append('payment', form.payment);
    {form.image && formData.append('image', form.image);}

    dispatch(createCourse(formData, token))
  };

  const handleimageChange = (event) => {
    setForm({ ...form, image: event.target.files[0] });
  };

  const handleToggle = () => {
    setForm({...form, payment: !form.payment});
  };

  const removeCourse = (courseId) => {
    if (window.confirm('Are you sure you want to delete this course?')) {
           dispatch(deleteCourse(courseId, token))
        }
  }

  const editCourse = (course) => {
    console.log("editcoure", course)
    setForm({id: course.id,  courseName: course.name, description: course.description , payment: course.payment});
     setModalVisible(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  // const addCourse = (course) => {
  //   dispatch(createCourse(course, token))
  // }

  if (loading) return <Spinner size="large" />

    return (
		<div className='m-5'>
        <FlexSectionHeader>
          <Title level={3}>All Courses</Title>
          <Button
            onClick={() => setModalVisible(true)}
            type="dashed"
            shape="round"
            icon={<PlusOutlined />}
          >
            Add Course
          </Button>
        </FlexSectionHeader>

        <Modal
        title="Add New Course"
        visible={modalVisible}
        // onOk={form.submit}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10 }}>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <label htmlFor="courseName">courseName: </label>
            <input
              id="courseName"
              type="text"
              value={form.courseName}
              onChange={(e) => setForm({ ...form, courseName: e.target.value })}
              className="shadow-sm w-96 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            />
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <label htmlFor="description">description: </label>
            <input
              id="description"
              type="text"
              value={form.description}
              onChange={(e) => setForm({ ...form, description: e.target.value })}
              className="shadow-sm w-96 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
       
            />
          </div>

          <div>
            <input
              id="thumbnail"
              type="file"
              onChange={handleimageChange}
              accept="image/*"
              style={{ display: "none" }}
            //  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            />
            <label
              htmlFor="thumbnail"
              className="w-60 py-2 px-3 cursor-pointer flex items-center space-x-4 rounded-lg border border-gray-200 bg-primary-700 hover:bg-primary-800 hover:text-blue-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 "
            >
              <svg
                className="w-4 h-4 mr-2 -ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
                <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
              </svg>
              Upload thumbnail
            </label>
            {form.image && <img
              className="my-4 rounded-lg w-44 h-28 sm:mb-0 xl:mb-4 2xl:mb-0"
              src={
                URL.createObjectURL(form.image)
              }
              alt="picture"
            />}
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: 10 , marginTop: 10 }}>
          <label htmlFor="switch" className={form.payment ? 'on' : 'off'}> payment : </label>
          <input type="checkbox" checked={form.payment} onChange={handleToggle} />
          </div>

          </div>

        {/* <Form
          name="add Course"
          form={form}
          onFinish={addCourse}
          requiredMark={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        > */}
          {/* <Form.Item
            name="courseName"
            label="Course Name"
            rules={[
              {
                required: true,
                message: 'Please enter the course name'
              }
            ]}
          >
            <Input placeholder="Course Name" />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="(Optional)" allowClear />
          </Form.Item>

          <Form.Item name="image" label="Cover Image">
            <Input placeholder="(Optional) Image Url, defaults to random colour" />
          </Form.Item>

          <Form.Item name="payment" label="payment">
                      <Switch />
          </Form.Item>*/}
          
        {/* </Form>  */}
      </Modal>

  
        <div style={{ marginTop: '8px' }}>
          <Collapse
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            defaultActiveKey={['1']}
            ghost
          >
            <Collapse.Panel header={<Text style={{ fontSize: "25px", color: "black", fontWeight: 600}}>Public Courses</Text>} key="1">
              <List
                grid={{
                  gutter: 24,
                  column: 3,
                  xs: 1,
                  sm: 2,
                  xxl: 5
                }}
                dataSource={publicCourses}
                renderItem={(course) => (
                  <List.Item>
                    <CourseCard
                      course={course}
                      removeCourse={() => removeCourse(course.id)}
                    //   handleEnroll={() => handleEnroll(course.id, user._id)}
                    //   handleUnenroll={() => handleUnenroll(course.id, user._id)}
                      editCourse={() => editCourse(course)}
                      onClick={() =>
                        handleCourseCardClick(
                          course.id,
                          user.id,
                          course.enrolled
                        )
                      }
                    />
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          </Collapse>
        </div>

        <div style={{ marginTop: '20px' }}>
          <Collapse
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            defaultActiveKey={['1']}
            ghost
          >
            <Collapse.Panel header={<Text style={{ fontSize: "25px", color: "black", fontWeight: 600}}>Paied Courses</Text>} key="1">
              <List
                grid={{
                  gutter: 24,
                  column: 3,
                  xs: 1,
                  sm: 2,
                  xxl: 5
                }}
                dataSource={paiedCourses}
                renderItem={(course) => (
                  <List.Item>
                    <CourseCard
                      course={course}
                      removeCourse={() => removeCourse(course.id)}
                    //   handleEnroll={() => handleEnroll(course.id, user._id)}
                    //   handleUnenroll={() => handleUnenroll(course.id, user._id)}
                    editCourse={() => editCourse(course)}
                      onClick={() =>
                        handleCourseCardClick(
                          course.id,
                          user.id,
                          course.enrolled
                        )
                      }
                    />
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          </Collapse>
        </div>

      </div>
    );
  }

export default Courses