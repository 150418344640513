// export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';

// export const uploadProgress = (progress) => ({
//   type: UPLOAD_PROGRESS,
//   payload: progress
// });

// reducer.js
// const initialState = {
//   uploadProgress: 0
// };

// export default (state = initialState, action) => {
//   switch (action.type) {
//     case UPLOAD_PROGRESS:
//       return {
//         ...state,
//         uploadProgress: action.payload
//       };
//     default:
//       return state;
//   }
// };


import { GLOBALTYPES } from '../actions/globalTypes'

const initialState = {
    uploadHome: 0,
    uploadModule: 0
  };

const uploadProgressReducer = (state = initialState, action) => {
    switch (action.type){
        case GLOBALTYPES.UPLOAD:
            return action.payload;
        default:
            return state;
    }
}


export default uploadProgressReducer