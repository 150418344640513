import React from 'react'
import ReactPlayer from 'react-player';

import styled from 'styled-components'
import { BASE_URL } from '../../../utils/config';

export const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
`

export const VideoFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Video = (props) => {
  const { selectedLecture } = props
  console.log("selectedLecture.file", selectedLecture.file)
  return (
    <div>
    {selectedLecture.type == 'video'? <VideoWrapper>
      <VideoFrame
        title="courseVideo"
        src={`https://www.youtube.com/embed/${selectedLecture.videoId}`}
       frameBorder="0"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
        allowFullScreen
      ></VideoFrame>
    </VideoWrapper>:
    <div style={{display: "flex", alignContent: "center", justifyContent: "center",  
    width: "auto" , height: 500, }}>
    <video width="auto"  controls  >
    <source src={`${BASE_URL}${selectedLecture.file}`} type="video/mp4" />
     </video>
     <ReactPlayer
      url={selectedLecture.file}
      controls
      width="auto"
      height="auto"
      preload="auto"
    />
     </div>}
    </div>
  )
}

export default Video
