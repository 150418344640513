import React from 'react';
import "./rotatingCoin.scss";
import profile from '../../data/profile.png';

const RotatingCoin = () => (
  <div class="coin">
    <div class="coin__front"></div>
    <div class="coin__edge">
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>

    </div>
    <div class="coin__back"></div>
    <div class="coin__shadow"></div>
  </div>
  // <img
  //             className="rounded-full w-8 h-8"
  //             src={ profile}
  //             alt="user-profile"
  //           /> 
);

export default RotatingCoin;