import { combineReducers } from 'redux';
import auth from './authReducer';
import alert from './alertReducer';
import upload from './uploadProgressReducer';
import users from './usersReducer';
import exam from  './questionReducer';
import home from  './homeReducer';
import courseReducer from '../../redux2/reducers/courseReducer'
import moduleReducer from '../../redux2/reducers/moduleReducer'
import lectureReducer from '../../redux2/reducers/lectureReducer';



export default combineReducers({
  auth,
  alert,
  upload,
  users,
  exam,
  home,

  courses: courseReducer,
  modules: moduleReducer,
  lectures: lectureReducer,
});
