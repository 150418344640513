import { HOME_TYPES } from '../actions/HomeAction'
import { EditData, DeleteData } from '../actions/globalTypes'

const initialState = {
    HVideo: [],
}

const homeReducer  = (state = initialState, action) => {
    switch (action.type){
        case HOME_TYPES.GET_HOMEVIDEO:
            return {
                ...state,
                
                HVideo: action.payload,
            };
        case HOME_TYPES.CREATE_HOMEVIDEO:
            return {
                ...state,
                HVideo: [action.payload, ...state.HVideo]
            };
        case HOME_TYPES.DELETE_HOMEVIDEO:
            return {
                ...state,
                HVideo: DeleteData(state.HVideo, action.payload)
            };
        default:
            return state;
        // case GLOBALTYPES.USERS:
        //     return action.payload;
        // default:
        //     return state;
    }
}


export default homeReducer 