import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { createUnit, deleteUnit, deleteYear, getQuestion, getUnits, getYear, unitProcess } from '../redux/actions/questionAction';
import { Link } from 'react-router-dom';
import { IoIosCloseCircle } from "react-icons/io";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { createYear } from "../redux/actions/questionAction";
import { useStateContext } from '../contexts/ContextProvider';
import { Button, Header } from '../components';
import { MdOutlineDeleteForever } from "react-icons/md";
import { FcProcess } from "react-icons/fc";
import '../style/units.css'

const Units = () => {

    const { currentColor } = useStateContext();

    const { auth, exam } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [questions, setQuestions] = useState([])
    const [units, setUnits] = useState([])

    console.log('units UUUUUUUUU', units)

    useEffect(() => {
        setUnits(exam.units)
    }, [exam]);

    useEffect(() => {
        if (auth.token) {
            const subject = 'all'
            dispatch(getUnits({ subject, auth }))
        }
    }, [auth]);

    const [unitData, setUnitData] = useState({});
    const {
        subject,
        grade,
        unit,
        title,
    } = unitData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setUnitData({ ...unitData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!subject) {
            dispatch({
                type: GLOBALTYPES.ALERT,
                payload: { error: "Select Subject" },
            });
            return;
        }

        dispatch(createUnit({ unitData, auth }));

    };

    const handleProcess = (e) => {
        e.preventDefault();

        dispatch(unitProcess(auth))

    };

    

    return (
        <div>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                <div className="flex gap-10">
                    <Header category="Page" title="Units" />
                </div>

                <div className="p-4 mb-10 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                    <h3 className="mb-4 text-xl font-semibold dark:text-white">
                        Add Units
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label
                                    for="grade"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Grade
                                </label>
                                <input
                                    type="number"
                                    name="grade"
                                    id="grade"
                                    onChange={handleChangeInput}
                                    value={grade}
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="9"
                                    required
                                />
                            </div>
                            <div class="col-span-6 sm:col-span-3">
                                <label
                                    for="subject"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Subject
                                </label>
                                <select id="subject"
                                    name='subject'
                                    onChange={handleChangeInput}
                                    value={subject}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                    <option selected="">{subject ? subject : "Select Subject"}</option>
                                    <option value="Biology">Biology</option>
                                    <option value="Chemistry">Chemistry</option>
                                    <option value="Maths">Maths</option>
                                    <option value="English">English</option>
                                    <option value="Physics">Physics</option>
                                    <option value="Geography">Geography</option>
                                    <option value="History">History</option>
                                    <option value="Civics">Civics</option>
                                </select>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label
                                    for="unit"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Unit
                                </label>
                                <input
                                    type="number"
                                    name="unit"
                                    id="unit"
                                    onChange={handleChangeInput}
                                    value={unit}
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="9"
                                    required
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label
                                    for="title"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Title
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    onChange={handleChangeInput}
                                    value={title}
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                    placeholder="DNA"
                                    required
                                />
                            </div>

                            <div className="col-span-6 sm:col-full">
                                <button
                                    style={{ backgroundColor: currentColor }}
                                    className={`text-white  hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
                                    type="submit"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                {/* // flex-wrap justify-center gap-10 items-center"> */}
                <div className='flex justify-end'>
          <button
      type="button"
      onClick={handleProcess}
        
      style={{ backgroundColor:"#E0E0E0", borderRadius: '50%' }}
      className={` text-2xl p-3  hover:drop-shadow-xl`}
    >
      <FcProcess />
    </button>
          </div>

                <div>
                    {Object.entries(units).map(([subject, grades]) => (
                        <div key={subject}
                            className='m-5 shadow-sm bg-gray-50 border border-gray-300 rounded-lg block p-2.5 dark:bg-gray-700 dark:border-gray-600'>
                            <h2 className='text-xl font-semibold dark:text-white'>{subject}</h2>
                            {Object.entries(grades).map(([grade, units]) => (
                                <div key={grade} className='ml-10'>
                                    <h3 className='mt-8 mb-2  text-xl font-semibold dark:text-white'>Grade {grade}</h3>
                                    <table className='ml-12'>
                                        {units.map((unit) => (
                                            <tr key={`${subject}-${grade}-${unit.unit}`} className='ml-6'>
                                                <td>Unit {unit.unit}</td>
                                                <td>{unit.title}</td>
                                                <td> {unit.number_of_Questions || 'N/A'}</td>
                                                <td
                                                      className='flex justify-center'
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                `Are you sure you want to delete header?`
                                                            )
                                                        ) {
                                                            const id = unit.id
                                                            dispatch(deleteUnit(id, auth));
                                                        }
                                                    }}
                                                >
                                                    <MdOutlineDeleteForever style={{ fontSize: "25px", color: "red" }} />
                                                
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

}

export default Units


