export const courses = [{
    _id: "669946654ecc7e65fc13b351", // Unique identifier (likely from MongoDB)
    files: [], // Empty array (possibly for future attachments)
    status: "published", // Indicates the course is publicly available
    name: "maths", // Course title
    description: "asmdkasm", // Course description (may need clarification)
    createdBy: "66993b44f5b3640abc10dd7a", // User ID of the creator (reference to a separate user object)
    image: "https://assets.atdw-online.com.au/images/a11021df8ac18bcfc096922562e5b24f.jpeg?rect=222", // Course image URL (clarify relevance)
    createdAt: new Date("2024-07-18T16:44:21.518Z"), // Date and time of creation in UTC
    backgroundColor: "#6e991", // Background color for the course UI (optional)
    modules: [
      {
        _id: "669946af4ecc7e65fc13b381", // Unique identifier (likely from MongoDB)
        title: "math", // Module title
        moduleItems: [
          {
            _id: "669946cd4ecc7e65fc13b384", // Unique identifier (likely from MongoDB)
            title: "maths unit", // Module item title
            type: "video", // Type of content (e.g., video, text, quiz)
            url: "https://youtu.be/f1R_bykXHGE?list=RDCMUCWEHue8kksIaktO8KTTN_zg", // URL for the content
          },
          {
            _id: "669949254ecc7e65fc13b3c9", // Unique identifier (likely from MongoDB)
            title: "bbbb", // Module item title
            type: "video", // Type of content (e.g., video, text, quiz)
            url: "https://youtu.be/uvtkwOpwAYU", // URL for the content
          },
          {
            _id: "66994d294ecc7e65fc13b40a", // Unique identifier (likely from MongoDB)
            title: "test", // Module item title
            type: "video", // Type of content (e.g., video, text, quiz)
            url: "https://youtu.be/Pe_-TkdbXN0", // URL for the content
          },
          {
            _id: "6699500579304959102a091a", // Unique identifier (likely from MongoDB)
            title: "zvczxv", // Module item title
            type: "video", // Type of content (e.g., video, text, quiz)
            url: "https://youtu.be/Pe_-TkdbXN0", // URL for the content
          },
        ],
      },
      {
        _id: "669a21158a84475bf034b5c9", // Unique identifier (likely from MongoDB)
        title: "222", // Module title
        moduleItems: [
          {
            _id: "669a2f178a84475bf034b5e6", // Unique identifier (likely from MongoDB)
            title: "chmical eglibrum", // Module item title (may need correction)
            type: "video", // Type of content (e.g., video, text, quiz)
            url: "https://youtu.be/GH5pC_ixeWw", // URL for the content
          },
        ],
      },
    ],
    enrollments: [
      {
        _id: "6699469e4ecc7e65fc13b377", // Unique identifier (likely from MongoDB)
        user: "66993b44f5b3640abc10dd7a", // User ID of the enrolled user (reference to a separate user object)
        enrolledAs: "instructor", // Enrollment role (e.g., instructor, student)
        createdAt: new Date("2024-07-18T16:45:18.578Z"), // Date and time of enrollment in UTC
      },
    ],
    __v: 9, // Version number of the document (may be used for tracking changes)
  },
  {
    _id: "669a30738a84475bf034b61d", // Unique identifier (likely from MongoDB)
    files: [], // Empty array (possibly for future attachments)
    status: "published", // Indicates the course is publicly available
    name: "physics", // Course title
    description: "asdf", // Course description (may need clarification)
    createdBy: "66993b44f5b3640abc10dd7a", // User ID of the creator (reference to a separate user object)
    image: "https://th.bing.com/th/id/R.31e01e1c75103e4e59fd80c0fca692ef?rik=pYm6SSYFeaYaWA&pid=ImgRaw&r=0", // Course image URL
    createdAt: new Date("2024-07-19T09:22:59.830Z"), // Date and time of creation in UTC
    backgroundColor: "#3278d5", // Background color for the course UI (optional)
    modules: [
      {
        _id: "669a30a28a84475bf034b628", // Unique identifier (likely from MongoDB)
        title: "physics 1", // Module title
        moduleItems: [
          {
            _id: "669a30e28a84475bf034b62b", // Unique identifier (likely from MongoDB)
            title: "Pilot Exam Questions", // Module item title
            type: "video", // Type of content (e.g., video, text, quiz)
            url: "https://youtu.be/onSSLxGyrnw", // URL for the content
          },
        ],
      },
    ],
    enrollments: [
      {
        _id: "669a30738a84475bf034b61e", // Unique identifier (likely from MongoDB)
        user: "66993b44f5b3640abc10dd7a", // User ID of the enrolled user (reference to a separate user object)
        enrolledAs: "instructor", // Enrollment role (e.g., instructor, student)
        createdAt: new Date("2024-07-19T09:22:59.831Z"), // Date and time of enrollment in UTC
      },
    ],
    __v: 2, // Version number of the document (may be used for tracking changes)
  }];




export const user = {
  _id: "66993b44f5b3640abc10dd7a", // Unique identifier (likely from MongoDB)
  photo: "https://www.w3schools.com/howto/img_avatar.png", // Profile picture URL
  passwordChangedAt: new Date("2024-07-18T15:46:08.860Z"), // Date and time of last password change (UTC)
  isActive: true, // Indicates if the user account is active
  lastSeenAt: new Date("1970-01-01T00:00:00.000Z"), // Date and time of last user activity (likely placeholder)
  invalidatedTokens: [
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...", // List of invalidated JWT tokens
    // ... potentially more tokens
  ],
  role: "instructor", // User role (e.g., instructor, student)
  enrollments: [
    "669946654ecc7e65fc13b351", // Enrolled course IDs (likely references to course objects)
    "669a30738a84475bf034b61d",
  ],
  isEmailRegistered: false, // Indicates if the user has registered an email address
  email: "instructor@example.com", // User email address (if registered)
  // Password property is typically omitted for security reasons
  // password: "$2a$08$s.IP81N0rRNed/Fx8AXTsOT3Hewfst124feWmRav2jHGSOSzFacgK",
  name: "robel", // User's full name
  username: "robel", // User's login username
  mobile: "091-011-8088", // User's mobile phone number
  passwordConfirm: "shortpass", // Possibly a confirmation password during signup (omit for security)
  code: 1721318212864, // Unclear purpose (needs clarification)
  __v: 9, // Version number of the document (may be used for tracking changes)
};



export const modules = [
    {
      _id: "669946af4ecc7e65fc13b381", // Unique identifier (likely from MongoDB)
      title: "math", // Module title
      moduleItems: [
        {
          _id: "669946cd4ecc7e65fc13b384", // Unique identifier (likely from MongoDB)
          title: "maths unit", // Module item title
          type: "video", // Type of content (e.g., video, text, quiz)
          url: "https://youtu.be/f1R_bykXHGE?list=RDCMUCWEHue8kksIaktO8KTTN_zg", // URL for the content
        },
        {
          _id: "669949254ecc7e65fc13b3c9", // Unique identifier (likely from MongoDB)
          title: "bbbb", // Module item title
          type: "video", // Type of content (e.g., video, text, quiz)
          url: "https://youtu.be/uvtkwOpwAYU", // URL for the content
        },
        {
          _id: "66994d294ecc7e65fc13b40a", // Unique identifier (likely from MongoDB)
          title: "test", // Module item title
          type: "video", // Type of content (e.g., video, text, quiz)
          url: "https://youtu.be/Pe_-TkdbXN0", // URL for the content
        },
        {
          _id: "6699500579304959102a091a", // Unique identifier (likely from MongoDB)
          title: "zvczxv", // Module item title
          type: "video", // Type of content (e.g., video, text, quiz)
          url: "https://youtu.be/Pe_-TkdbXN0", // URL for the content
        },
      ],
    },
    {
      _id: "669a21158a84475bf034b5c9", // Unique identifier (likely from MongoDB)
      title: "222", // Module title
      moduleItems: [
        {
          _id: "669a2f178a84475bf034b5e6", // Unique identifier (likely from MongoDB)
          title: "chmical eglibrum", // Module item title (may need correction)
          type: "video", // Type of content (e.g., video, text, quiz)
          url: "https://youtu.be/GH5pC_ixeWw", // URL for the content
        },
      ],
    },
  ]



 export const moduleItems = [
    {
      id: '669946cd4ecc7e65fc13b384',
      title: 'maths unit',
      url: 'https://youtu.be/f1R_bykXHGE?list=RDCMUCWEHue8kksIaktO8KTTN_zg',
      type: 'video',
      videoId: 'f1R_bykXHGE',
      // ... other properties from the original object (if any)
    },
    {
      id: '669949254ecc7e65fc13b3c9',
      title: 'bbbb',
      url: 'https://youtu.be/uvtkwOpwAYU',
      type: 'video',
      videoId: 'uvtkwOpwAYU',
      // ... other properties
    },
    {
      id: '66994d294ecc7e65fc13b40a',
      title: 'test',
      url: 'https://youtu.be/Pe_-TkdbXN0',
      type: 'video',
      videoId: 'Pe_-TkdbXN0',
      // ... other properties
    },
    {
      id: '6699500579304959102a091a',
      title: 'zvczxv',
      url: 'https://youtu.be/Pe_-TkdbXN0',
      type: 'video',
      videoId: 'Pe_-TkdbXN0',
      // ... other properties
    },
    {
      id: '669a2f178a84475bf034b5e6',
      title: 'chmical eglibrum', // May need correction
      url: 'https://youtu.be/GH5pC_ixeWw',
      type: 'video',
      videoId: 'GH5pC_ixeWw',
      // ... other properties
    },
  ];