import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { getQuestion, getQuestion_byid, updateQuestion } from '../redux/actions/questionAction';
import { useSelector, useDispatch } from "react-redux";
import { IoIosCloseCircle } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";
import authReducer from '../redux/reducers/authReducer';
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { useStateContext } from '../contexts/ContextProvider';
import { Header } from '../components';

const EditQuestion = () => {

    const { id } = useParams();

    const { currentColor } =useStateContext();

    const { auth, exam } = useSelector((state) => state);
    const dispatch = useDispatch();

    const [questionbyid, setQuestionbyid] = useState('')

    useEffect(() => {
        if (auth){
      dispatch(getQuestion_byid(id, auth))
        }
      }, [auth]);
      

      useEffect(() => {
        setQuestionbyid(exam.questionByid)
      }, [exam.questionByid]);


    const [questionData, setQuestionData] = useState({});
    const {
        question_pic = questionbyid?.question_pic,
         question = questionbyid?.question,
         option1 = questionbyid?.option1,
         option2= questionbyid?.option2,
         option3= questionbyid?.option3,
         option4 = questionbyid?.option4,
         answer = questionbyid?.answer,
         explanation = questionbyid?.explanation,
         explanation_pic = questionbyid?.explanation_pic,
         grade = questionbyid?.grade,
         unit = questionbyid?.unit,
    } = questionData;

   


    const [selectedImage, setSelectedImage ] = useState('');
     const [explanationImage, setExplanationImage ] = useState('');


   

    const handleChangeQuestionInput = (e) => {
        const { name, value } = e.target;
        setQuestionData({ ...questionData, [name]: value });
    };

    

    const handleImageChange = (event) => {
        const file = event.target.files[0]; // Access the selected image file
        setSelectedImage(file);

        setQuestionData({ ...questionData, question_pic: event.target.files[0] });
    };

    const handleExplanationImage = (event) => {
        const file = event.target.files[0]; 
        setExplanationImage(file);

        setQuestionData({ ...questionData, explanation_pic: event.target.files[0] });
    };

    const removeFile = name => {
        if (name == 'question_pic'){
            setSelectedImage('')
            setQuestionData({ ...questionData, question_pic: "#deleted" });
        } else if (name = 'explanation_pic'){
          setExplanationImage('')
          setQuestionData({ ...questionData, explanation_pic: "#deleted" });
        }
      }
     

    const handleSubmitQuestion = (e) => {
        e.preventDefault();
        dispatch(updateQuestion({ id, questionData, auth}));
           
      };

  

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="">
        <div className="flex gap-10">
          <Header category="Page" title="Edit Question" />
          </div>
    
    
    <div className="p-4 mb-10 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                        <h3 className="mb-4 text-xl font-semibold dark:text-white">
                            Edit Question
                        </h3>
                        <form onSubmit={handleSubmitQuestion}>
                            <div className="grid grid-cols-6 gap-6">

                               <div className="col-span-12 sm:col-span-6">
                                    <label
                                        for="question"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Question
                                    </label>
                                    <textarea
                                        name="question"
                                        id="question"
                                        onChange={handleChangeQuestionInput}
                                        value={question}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                 <input id="question_pic" type="file" onChange={handleImageChange}
                                    accept="image/*" style={{display: "none"}}
                                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                  />
                                  <label htmlFor="question_pic" className="cursor-pointer flex items-center space-x-4">
                                    <svg className="w-4 h-4 mr-2 -ml-1" fill="currentColor"
                                       viewBox="0 0 20 20"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                     d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>   
                                    <path
                                      d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
                                    </svg>
                                      Upload Question picture
                                  </label>
                                  {/* {selectedImage && (
                                      <img
                                        className="mb-4 rounded-lg w-60 h-auto sm:mb-0 xl:mb-4 2xl:mb-0"
                                        src={URL.createObjectURL(selectedImage)}
                                        alt="Selected Image"
                                      />
                                    )} */}
                                    <div className='relative my-4  w-28 h-auto sm:mb-0 xl:mb-4 2xl:mb-0'>
                                  {(selectedImage || (question_pic && question_pic != '#deleted' )) && <>
                                    <img
                                className="rounded-lg"
                                src={
                                    selectedImage
                                        ? URL.createObjectURL(selectedImage)
                                        : question_pic
                                }
                                alt="picture"
                            />
                            <button
                 type='button'
                 className='absolute -top-3 -right-3 bg-white rounded-full flex justify-center items-center'
                onClick={() => removeFile('question_pic')}
              >
                <IoIosCloseCircle style={{ fontSize: "25px", color: "red" }} />
              </button>
              </>}
              </div>
                                </div>


                              <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="option1"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Option 1
                                    </label>
                                    <input
                                        type="text"
                                        name="option1"
                                        id="option1"
                                        onChange={handleChangeQuestionInput}
                                        value={option1}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="option2"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Option 2
                                    </label>
                                    <input
                                        type="text"
                                        name="option2"
                                        id="option2"
                                        onChange={handleChangeQuestionInput}
                                        value={option2}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="option1"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Option 3
                                    </label>
                                    <input
                                        type="text"
                                        name="option3"
                                        id="option3"
                                        onChange={handleChangeQuestionInput}
                                        value={option3}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        for="option4"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Option 4
                                    </label>
                                    <input
                                        type="text"
                                        name="option4"
                                        id="option4"
                                        onChange={handleChangeQuestionInput}
                                        value={option4}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        required
                                    />
                                </div>

                                <div class="col-span-3 sm:col-span-2">
                                <label
                                        for="answer"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Answer
                                    </label> 
                                      <select id="answer"
                                        name='answer'
                                        onChange={handleChangeQuestionInput}
                                        value={answer}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                        <option selected="">{answer ? answer : "Select Answer"}</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                        <option value="option4">option4</option>
                                    </select>
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label
                                        for="explanation"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Explanation
                                    </label>
                                    <textarea
                                        name="explanation"
                                        id="explanation"
                                        onChange={handleChangeQuestionInput}
                                        value={explanation}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        // placeholder="2000"
                                        // required
                                    />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                 <input id="explanationImage" type="file" onChange={handleExplanationImage}
                                    accept="image/*" style={{display: "none"}}
                                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                  />
                                  <label htmlFor="explanationImage" className="cursor-pointer flex items-center space-x-4">
                                    <svg className="w-4 h-4 mr-2 -ml-1" fill="currentColor"
                                       viewBox="0 0 20 20"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path
                                     d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>   
                                    <path
                                      d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
                                    </svg>
                                      Upload explanation picture
                                  </label>
                                
                                <div className='relative my-4  w-28 h-auto sm:mb-0 xl:mb-4 2xl:mb-0'>
                                  {(explanationImage || (explanation_pic && explanation_pic != '#deleted')) && <>
                                    <img
                                className="rounded-lg"
                                src={
                                    explanationImage
                                        ? URL.createObjectURL(explanationImage)
                                        : explanation_pic
                                }
                                alt="picture"
                            />
                            <button
                 type='button'
                 className='absolute -top-3 -right-3 bg-white rounded-full flex justify-center items-center'
                onClick={() => removeFile('explanation_pic')}
              >
                <IoIosCloseCircle style={{ fontSize: "25px", color: "red" }} />
              </button>
              </>}
              </div>
              </div>
              
              <div className="col-span-1 ">
                                    <label
                                        for="grade"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Grade
                                    </label>
                                    <input
                                        type="number"
                                        name="grade"
                                        id="grade"
                                        onChange={handleChangeQuestionInput}
                                        value={grade}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        
                                    />
                                </div>

                                <div className="col-span-1 ">
                                    <label
                                        for="unit"
                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                        Unit
                                    </label>
                                    <input
                                        type="number"
                                        name="unit"
                                        id="unit"
                                        onChange={handleChangeQuestionInput}
                                        value={unit}
                                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                      
                                    />
                                </div>
                                
                                
                                
                                
                                
                                
                                
                                <div className="col-span-6 sm:col-full">
                                    <button
                                        style={{ backgroundColor: currentColor }}
                                        className={`text-white  hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    </div>
                    </div>

  )
}

export default EditQuestion