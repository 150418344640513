import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { login } from "../redux/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import barcodeScanner from '../data/barcode-scanner.svg';
import { GLOBALTYPES } from '../redux/actions/globalTypes'
import  Z_Secret_Training  from '../data/Z_Secret_Training.jpg';

const Login = () => {

  const { auth, alert } = useSelector((state) => state);
  const dispatch = useDispatch();
  // const history = useHistory();

  const initialState = { email: "", password: "" };
  const [userData, setUserData] = useState(initialState);
  const { email, password } = userData;

  const [typePass, setTypePass] = useState(false);

  

  useEffect(() => {
    if (auth.token);
  }, [auth.token]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  function validateEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const [errEmail, setErrEmail] = useState("");
const [errPassword, setErrPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrEmail("")
    setErrPassword("")
    
    if(!email) {
      const email1 = "Please add your email."
      return setErrEmail(email1)
    }else if(!validateEmail(email)){
      const email1 = "Email format is incorrect."
      return setErrEmail(email1)
  }

  if(!password) {
      const password1 = "Please add your password."
      return setErrPassword(password1)
  }else if(password.length < 2){
      const password1 = "Password must be at least 2 characters."
      return setErrPassword(password1)
  }
    
    dispatch(login(userData));
  };

  return (

    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img className="w-10 h-10 mr-2" src={Z_Secret_Training} alt="logo" 
          style={{width: 40, height: 40, borderRadius: "50%", marginRight: 20}}></img>
          ZSceret Academy
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Login
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChangeInput}
                  value={email}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  required=""
                  style={{ background: `${errEmail ? "#fd2d6a14" : ""}` }}
                ></input>
                <small className="text-sm font-medium text-red-500">
                  {errEmail ? errEmail : ""}
                </small>
              </div>
              <div>
                <label
                  for="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={handleChangeInput}
                  value={password}
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                  style={{ background: `${errPassword ? "#fd2d6a14" : ""}` }}
                ></input>
                <small className="text-sm font-medium text-red-500">
                  {errPassword ? errPassword : ""}
                </small>
              </div>
              
              <button
                type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-400 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Login
              </button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                 You don't have an account?{' '}
                <a className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                  {/* <Link to="/register" style={{ color: "crimson" }}>
                     Register here
                  </Link> */}
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
