import React, { useEffect, useState } from 'react'
import { FlexSectionHeader } from '../edu-hub/style'
import {Button, Typography, Modal, Input, List, Collapse, Col, Empty,Form, Row, Switch, Upload } from 'antd'
import {
    CaretRightOutlined,
    ExclamationCircleOutlined,
    PlusOutlined
  } from '@ant-design/icons'
// import { courses, user } from '../data/course_data'
// import { user } from '../data/course_data'
import CourseCard from '../edu-hub/components/CourseCard'
import Spinner from '../edu-hub/components/Spinner'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCourse,
  getAllCourses,
  deleteCourse,
  unEnroll
} from '../redux2/reducers/courseReducer'
import { createHomeVideo, deleteHomeVideo, getHomeVideo } from '../redux/actions/HomeAction'
import { GLOBALTYPES } from '../redux/actions/globalTypes'
import { IoIosCloseCircle } from "react-icons/io";
import { BASE_URL } from '../utils/config'

const { Title, Text } = Typography

const Home = () => {

    const dispatch = useDispatch()

    const token = useSelector((state) => state.auth.token)
    const HVideo = useSelector((state) => state.home.HVideo)
    const uploadProgress = useSelector(state => state.upload.uploadHome)


    // console.log("uploadProgress HHHHHHHHHHHUUUUUUUUUUUUUUU", uploadProgress )


    const initialState = {
        title: '',
        file: null,
        thumbnail: null
      };
      
      const [form, setForm] = useState(initialState);
      const [HomeVideo, setHomeVideo] = useState([]);

      useEffect(() => {
        if (token){
      dispatch(getHomeVideo(token))
        }
      }, [token]);

      useEffect(() => {
        setHomeVideo(HVideo)
      }, [HVideo]);

      


//   const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  const removeHomeVideo = (id) => {
    if (window.confirm('Are you sure you want to delete this Video?')) {
           dispatch(deleteHomeVideo(id, token))
        }
  }

  const handleCancel = () => {
    setModalVisible(false)
    setForm(initialState)
  }




  const handleFileChange = (event) => {
    setForm({ ...form, file: event.target.files[0], title: event.target.files[0].name });

  };

  const handlethumbnailChange = (event) => {
    setForm({ ...form, thumbnail: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if(form.title == '' || form.file == null ||  form.thumbnail == null){
      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: 'Fill All Form!' },
      });
    }

    const formData = new FormData();
    formData.append('file', form.file);
    formData.append('title', form.title);
    formData.append('thumbnail', form.thumbnail);
   
    dispatch(createHomeVideo(formData, token))

    setModalVisible(false)
    setForm(initialState)
  };




    return (
		<div className='m-5'>
        <FlexSectionHeader>
          <Title level={3}>Home page</Title>
          <Button
            onClick={() => setModalVisible(true)}
            type="dashed"
            shape="round"
            icon={<PlusOutlined />}
          >
             Home video
          </Button>
        </FlexSectionHeader>

        <Modal
        title="Add Video"
        visible={modalVisible}
        // onOk={form.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        ]}
      >
    <form>
      <div style={{ marginTop: '8px', width: '100%' }}>
        <div style={{}}>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <label htmlFor="fileName">File Name:</label>
            <input
              id="fileName"
              type="text"
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
              className="shadow-sm w-60 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            />
          </div>
          <br />
          {/* <label htmlFor="fileInput">File:</label>
        <input
          id="fileInput"
          type="file"
          onChange={handleFileChange}
          required
        /> */}
          <div>
            <input
              id="thumbnail"
              type="file"
              onChange={handlethumbnailChange}
              accept="image/*"
              style={{ display: "none" }}
              required
            //  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            />
            <label
              htmlFor="thumbnail"
              className="w-60 py-2 px-3 cursor-pointer flex items-center space-x-4 rounded-lg border border-gray-200 bg-primary-700 hover:bg-primary-800 hover:text-blue-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 "
            >
              <svg
                className="w-4 h-4 mr-2 -ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
                <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
              </svg>
              Upload thumbnail
            </label>
            {form.thumbnail && <img
              className="my-1 rounded-lg w-40 h-25 sm:mb-0 xl:mb-4 2xl:mb-0"
              src={
                URL.createObjectURL(form.thumbnail)
              }
              alt="picture"
            />}
          </div>
        </div>

        <div style={{ marginTop: 20}}>
            <input
              id="file"
              type="file"
              onChange={handleFileChange}
              accept="video/*"
              style={{ display: "none" }}
              required
            //  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            />
            <label
              htmlFor="file"
              className="w-60 py-2 px-3 cursor-pointer flex items-center space-x-4 rounded-lg border border-gray-200 bg-primary-700 hover:bg-primary-800 hover:text-blue-700 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 "
            >
              <svg
                className="w-4 h-4 mr-2 -ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
                <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
              </svg>
              Upload video
            </label>
            {form.file && 
                <video
                  src={ URL.createObjectURL(form.file)}
                  alt={form.file.name}
                  width={100}
                  height={20}
                  // autoPlay
                  controls
                  muted
                  className="mt-2 h-auto max-h-24 w-40 object-cover rounded-md"
                />
              }
          </div>

      </div>
    </form>
        
      </Modal>

    <div>
      <Text  className='text-2xl mt-10'>{uploadProgress > 0 ? `uploadProgress: ${uploadProgress}%` : ''}</Text>
      <div className="flex m-3 flex-wrap  gap-10 items-center">
    {HomeVideo.map((item) => (
      <div key={item.title} 
    //   className="bg-white h-44 w-80 shadow-lg flex justify-center items-center    dark:text-gray-200 dark:bg-secondary-dark-bg p-4 pt-9 rounded-2xl "
       style={{ position: 'relative'}}
       className='h-44 w-80 shadow-lg rounded-2xl'
      >
        {/* <button
        //  onClick={() => handleSelectSubject(item.title)}
        >
        <p className=" text-xl text-black font-bold">{item.title}</p>
        </button> */}
        <button
       type='button'
       style={{ position: "absolute", right: 5, backgroundColor: 'white', borderRadius: 15}}
       className=''
      onClick={() => removeHomeVideo(item.id)}
    >
      <IoIosCloseCircle style={{ fontSize: "25px", color: "red" }} />
    </button>
         <img
           style={{
             objectFit: 'cover',
            //  objectPosition: 'top'
           }}
           alt="course img"
           src={`${BASE_URL}${item.thumbnail}`}
           className='h-44 w-80 shadow-lg rounded-2xl'
         />
         <p className="mt-2 text-sm text-gray-500">{item.title}</p>

         
      </div>
    ))}
    </div>
   
      </div>
    </div>
    );
  }

export default Home

