import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import Alert from "./components/alert/Alert";
import Login from "./components/login";
import Register from "./components/register";

import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Profile,
  Dashboard,
  Users, 
  AdminEditUser,
  Not_Found,
  Exams,
  Add_Exam,
  Questions,
  EditQuestion,
  Units,
  Courses,
  Home,
  ExamPaidUsers,
  CoursePaidUsers,
} from "./pages";
import "./App.css";
import Modules  from "./pages/modules/Modules";

import { useStateContext } from "./contexts/ContextProvider";

import { useSelector, useDispatch } from "react-redux";
import { refreshToken } from "./redux/actions/authAction";
import PrivateRoutes from './utils/PrivateRoutes'

import "./data/fontawesome";

import 'antd/dist/antd.css'
import Lectures from "./pages/lectures";

const App = () => {
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshToken());
  }, [dispatch]);


 

  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <Alert />
        {/* <SocketClient /> */}
        <div className="flex relative dark:bg-main-dark-bg">
          {auth.token && <div className="fixed right-4 bottom-4" style={{ zIndex: "999" }}>
            <TooltipComponent content="Settings" position="Top">
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: "50%" }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>}
          {auth.token && activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              auth.token && activeMenu
                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full"
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
            }
          >
            {auth.token && <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg nav  w-full " style={{ zIndex: "999" }}>
              <Navbar />
            </div> }
            <div>
              {auth.token && themeSettings && <ThemeSettings />}

              <Routes>
                {/* auth */}
                <Route exact path="/" element={auth.token ? <Dashboard /> : <Login/>} />
                <Route exact path="/login" element={auth.token ? <Dashboard /> : <Login/>} />
                {/* <Route exact path="/register" element={auth.token ? <Dashboard /> : <Register/>} /> */}
                
                <Route element={<PrivateRoutes token={auth.token}/>}>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/Exams" element={<Exams />} />
                      <Route path="/Add_Exam" element={<Add_Exam/>} />
                      <Route path="/Units" element={<Units/>} />
                      <Route path="/Courses" element={<Courses/>} />
                      <Route path="/Home" element={<Home/>} />

                      <Route path="/Questions/:subject/:year" element={<Questions/>} />
                      <Route path="/EditQuestion/:id" element={<EditQuestion/>} />
                      <Route path="/app/course/:courseId/modules" element={<Modules/>} />
                      <Route path="/app/course/:courseId/lectures/:lectureId" element={<Lectures/>} />
                      <Route path="/ExamPaidUsers" element={<ExamPaidUsers/>} />
                      <Route path="/CoursePaidUsers" element={<CoursePaidUsers/>} />
                   
                      {auth?.user?.role == "admin" && <Route path="/Users" element={<Users />} />}
                      {auth?.user?.role == "admin" && <Route path="/AdminEditUser/:id_user" element={<AdminEditUser/>}/>}
                </Route>    
                
                <Route path="*" element={<Not_Found />} />
              </Routes>
            </div>
            {auth.token && <Footer />}
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
