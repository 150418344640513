 import React, { useEffect, useState } from 'react'
 import { useSelector, useDispatch } from "react-redux";
import { createEaxmPrice, deleteYear, enrollExam, getQuestion, getYear, multipleEnrollExam } from '../redux/actions/questionAction';
import { Link } from 'react-router-dom';
import { IoIosCloseCircle } from "react-icons/io";
import { Typography, Button, Modal, Form, Input, List } from 'antd'
import { FlexSectionHeader } from '../edu-hub/style';
import { PlusOutlined } from '@ant-design/icons';
 
 const Exams = () => {

  const { auth, exam } = useSelector((state) => state);
    const dispatch = useDispatch();   

    const { Title } = Typography
    const [form] = Form.useForm()

   const [selectedSubject, setSelectedSubject] = useState("")
   const [years, setYears] = useState("")
   const [addPrice, setAddPrice] = useState(false)

   useEffect(() => {
    setYears(exam.years)
  }, [exam]);

  const addCoursePrice = (price) => {
    dispatch(createEaxmPrice(price, auth.token))
  }

   const handleSelectSubject = (subject) => (
    setSelectedSubject(subject),
    dispatch(getYear({subject, auth}))
    )

    const removeYear = (id) => {
      // alert("Your file is being uploaded!")
      var answer = window.confirm("Are you sure you want to delete");
      if (answer) {
        dispatch(deleteYear({id, auth}))
      }
    }

    const [addUserEnroll, setAddUserEnroll] = useState(false)

    const handleCancel = () => {
      setAddUserEnroll(false)
      setAddPrice(false)
    }

    const addExamEnroll = (email) => {
      console.log("++++A addExamEnroll",email )
      // dispatch(enrollExam(email, auth.token))

      if(email.multiple_email){
        dispatch(multipleEnrollExam(email, auth.token))
      }
      else if (email.email){
        dispatch(enrollExam(email, auth.token))
      }
    }

    
   return (
    <div  className='m-10'>
       <FlexSectionHeader>
        <Title level={3}>Exam</Title>
        <div style={{display: "flex", gap: 20}}>
        <Button
            onClick={() => setAddUserEnroll(true)}
            type="dashed"
            shape="round"
            icon={<PlusOutlined />}
          >
            Add user to enroll
          </Button>
          <Button
            onClick={() => setAddPrice(true)}
            type="dashed"
            shape="round"
            icon={<PlusOutlined />}
          >
            Price
          </Button>
          </div>
      </FlexSectionHeader>


      <Modal
        title="Add User to Enroll"
        visible={addUserEnroll}
        onOk={form.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            Submit
          </Button>
        ]}
      >
        <Form
          name="add User to enroll"
          form={form}
          onFinish={addExamEnroll}
          requiredMark={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
        <Form.Item
            name="email"
            label="user email"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please enter the user email'
            //   }
            // ]}
          >
            <Input placeholder="email@com" type='email' />
          </Form.Item>

          <Form.Item
            name="multiple_email"
            label="multiple user email"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please enter the user email'
            //   }
            // ]}
          >
            <Input placeholder="email@com,email@com"/>
          </Form.Item>
        </Form>

        <a className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                  <Link to="/ExamPaidUsers" style={{ color: "crimson" }}>
                     paid users
                  </Link>
        </a>
      </Modal>

      <Modal
        title="Add Exam Price"
        visible={addPrice}
        onOk={form.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            Submit
          </Button>
        ]}
      >
        <Form
          name="add Price"
          form={form}
          onFinish={addCoursePrice}
          requiredMark={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            name="price"
            label="price"
            rules={[
              {
                required: true,
                message: 'Please enter the Exam price'
              }
            ]}
          >
            <Input placeholder="100" type='number' />
          </Form.Item>
        </Form>
      </Modal>
  
    <div className="flex m-3 flex-wrap justify-center gap-10 items-center">
    {!selectedSubject && subjects.map((item) => (
      <div key={item.title} className="bg-white h-44 min-w-40 shadow-lg flex justify-center items-center    dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
        <button
         onClick={() => handleSelectSubject(item.title)}
        >
        <p className=" text-xl text-black font-bold">{item.title}</p>
        </button>
      </div>
    ))}

   {selectedSubject && years?.map((item) => (
     <div className='relative'>
      <div key={item.title} className="bg-white h-44 min-w-40 shadow-lg flex justify-center items-center    dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
        {/* <button
         onClick={() => handleSelectYear(item.year)}
        > */}
        <Link to={`/Questions/${selectedSubject}/${item.year}`}>
        <p className=" text-xl text-black font-bold">{item.year}</p>
        </Link>
        {/* </button> */}
      </div>
       <button
       type='button'
       className='absolute -top-3 -right-3 bg-white rounded-full flex justify-center items-center'
      onClick={() => removeYear(item.id)}
    >
      <IoIosCloseCircle style={{ fontSize: "25px", color: "red" }} />
    </button>
    </div>
    ))}
  </div>
  </div>
   )
 }
 
 export default Exams


 const subjects = [
  {
    title: 'Biology',
  },
  {
    title: 'Chemistry',
  },
  {
    title: 'Maths',
  },
  {
    title: 'English',
  },
  {
    title: 'Physics',
  },
  {
    title: 'Geography',
  },
  {
    title: 'History',
  },
  {
    title: 'Civics',
  },
  {
    title: 'History',
  },
];


const years = [
  {
    title: '2016',
  },
  {
    title: '2015',
  },
  {
    title: 'Maths',
  },
  {
    title: 'English',
  },
  {
    title: 'Physics',
  },
  {
    title: 'Geography',
  },
  {
    title: 'History',
  },
  {
    title: 'Civics',
  },
  {
    title: 'History',
  },
];