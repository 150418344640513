export const GLOBALTYPES = {
  AUTH: 'AUTH',
  ALERT: 'ALERT',
  THEME: 'THEME',
  UPLOAD: 'UPLOAD'
};

export const EditData = (data, id, post) => {
  const newData = data.map((item) => (item.id === id ? post : item));
  return newData;
};

export const DeleteData = (data, id) => {
  console.log("data",data , "IIIDDDDD", id)
  const newData = data.filter((item) => item.id !== id);
  console.log("newData", newData)
  return newData;
};
